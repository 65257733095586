<template>
    <v-dialog v-model="factoryResetDialog" width="500" v-if="app.status !== 'Stopped'">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>Factory reset</v-card-title>
            <v-card-text class="pt-3">Application must be stopped first!</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="factoryResetDialog = false" :disabled="stoppingApp">Back</v-btn>
                <v-btn color="primary" text @click="stopApp" :loading="stoppingApp">Stop application</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="factoryResetDialog" width="500" v-else>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>Warning!</v-card-title>
            <v-card-text class="pt-3">
                <p>
                    Factory reset will clear all data installed into you application’s
                    <b>LIB</b>
                    area.
                </p>
                These are typically:
                <ul class="pb-4">
                    <li>software packages (Python, R, LaTeX, etc.)</li>
                    <li>applicaton-level configurations</li>
                    <li>applicaton add-on data (e.g. database files for MariaDB or Redis add-ons)</li>
                </ul>
                Factory reset
                <b>WILL NOT</b>
                affect the following files:
                <ul class="pb-4">
                    <li>application configuration and data files in your HOME are (Linux home ~)</li>
                    <li>data in you FILES area (Workspace files)</li>
                </ul>
                <v-btn outlined color="primary">Take a snapshot before Factory reset</v-btn>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="factoryResetDialog = false" :disabled="resettingApp">Back</v-btn>
                <v-btn color="primary" text @click="stopApp" :loading="resettingApp">Reset</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'FactoryReset',
    data() {
        return {
            stoppingApp: false,
            resettingApp: false,
            autobackUp: false,
            factoryResetDialog: false
        }
    },
    props: {
        app: { type: Object, required: true }
    },
    methods: {
        async stopApp() {
            const aid = this.app.aid
            const sessionId = this.$route.params.sessionId
            this.stoppingApp = true
            try {
                await this.$store.dispatch('appStore/stopApp', { aid, sessionId })
            } catch (error) {
            } finally {
                window.setTimeout(() => {
                    this.$store.dispatch('appStore/updateDeploymentStatus')
                }, 2000)
                this.stoppingApp = false
            }
        },
        async resetApp() {
            try {
                await this.$axios.post(`/application/${this.app.aid}/factory_reset`, {
                    auto_backup: this.autobackUp
                })
            } catch (error) {}
        }
    }
}
</script>
